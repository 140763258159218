<template>
  <div>
    <!-- 搜索列 -->
    <div class="merch-search">
      <div class="search-box">
        <!-- 类型选择 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">资质名称:</div> -->
          <el-input
            clearable
            placeholder="请输入资质名称"
            v-model="searchData.keyWords"
          ></el-input>
        </div>
        <!-- 搜索 -->
        <div class="sch-1 sch-2">
          <el-button type="primary" size="mini" @click="toSearch">
            查 询
          </el-button>
          <el-button
            type="success"
            size="mini"
            @click="showAddQu"
            v-if="allJurisdiction.manageType92"
            >新建</el-button
          >
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="merch-table scorr-roll">
      <div>
        <el-table :data="setGoodsWarData.data" border style="width: 100%" height="550px">
          <el-table-column prop="id" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="address" label="经营类别" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                effect="dark"
                :content="row.qualify_name"
                placement="bottom"
              >
                <div class="exceed">
                  {{ row.qualify_name }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="排序" align="center">
            <template slot-scope="{ row }">
              <el-tooltip effect="dark" :content="row.sort" placement="bottom">
                <div class="exceed">
                  {{ row.sort }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="170"
          >
            <template slot-scope="{ row }">
              <div class="userBtn">
                <!--  -->
                <div @click="editQul(row)" v-if="allJurisdiction.manageType93">
                  编辑
                </div>
                <el-popconfirm
                  @confirm="deleteQa(row.id)"
                  title="确定删除当前商品资质？"
                >
                  <div slot="reference" v-if="allJurisdiction.manageType94">
                    删除
                  </div>
                </el-popconfirm>
                <!-- <div @click="deleteQa(row.id)">删除</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :page-size="setGoodsWarData.per_page"
        layout="total, prev, pager, next, jumper"
        :total="setGoodsWarData.total"
        :current-page="searchData.page"
      >
      </el-pagination>
    </div>
    <!-- 新建 -->
    <el-dialog
      :title="AddData.id ? '编辑商品资质' : '新增商品资质'"
      :visible.sync="showQul"
      width="400px"
    >
      <div>
        <el-form
          ref="form1"
          :model="AddData"
          label-width="100px"
          :rules="addRule"
        >
          <el-form-item label="资质名称:" prop="qualify_name">
            <el-input
              v-model="AddData.qualify_name"
              placeholder="请输入资质名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="资质排序:" prop="sort">
            <el-input
              v-model="AddData.sort"
              type="number"
              placeholder="请输入资质排序"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideAddQu">取 消</el-button>
        <el-button type="primary" @click="subFormQul">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Basics");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      form: {},
      searchData: {
        page: 1,
      },
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "child",
        checkStrictly: true,
      }, //级联选择器配置项
      AreaArr: [],
      tableData: [],
      isTran: false, //控制转移弹框
      showQul: false,
      AddData: {
        qualify_name: "",
        sort: "",
        id: "",
      },
      addRule: {
        qualify_name: [
          { required: true, message: "请输入资质名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入资质排序", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["setGoodsWarData"]),
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    // this.getscopelist({ page: 1 });
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
    }
    this.getscopegoodsQualifyApi();
  },
  methods: {
    ...mapActions([
      "postgoodsQualifyDelte",
      "getscopegoodsQualify",
      "goodsQualifyAdds",
      "goodsQualifyEdit",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 删除资质库
    async deleteQa(id) {
      let data = await this.postgoodsQualifyDelte({ id: id });
      if (data.code == 200) {
        this.toSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    getscopegoodsQualifyApi() {
      this.getscopegoodsQualify(this.searchData);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      save.activeName = "1";
      save.router = router;
      this.changeSeach(save);
    },
    //
    handleCurrentChange(e) {
      this.searchData.page = e;
      this.getscopegoodsQualifyApi()
    },
    editQul(row) {
      this.AddData = row;
      this.showAddQu();
    },
    // 展示新建资质
    showAddQu() {
      this.showQul = true;
    },
    hideAddQu() {
      this.showQul = false;
      this.AddData = {
        qualify_name: "",
        sort: "",
        id: "",
      };
    },
    // 新增商品资质
    async subFormQul() {
      if (this.AddData.id) {
        let data = await this.goodsQualifyEdit(this.AddData);
        if (data.code == 200) {
          this.toSearch();
          this.hideAddQu();
        } else {
          this.$message.error(data.message);
        }
      } else {
        let data = await this.goodsQualifyAdds(this.AddData);
        if (data.code == 200) {
          this.toSearch();
          this.hideAddQu();
        } else {
          this.$message.error(data.message);
        }
      }
    },
    toSearch() {
      this.searchData.page = 1;
      this.getscopegoodsQualifyApi()
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 打开转移窗口
    transferS() {
      this.isTran = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .merch-content-top {
      padding-top: 10px;
      .chack-type {
        padding: 0px 40px;
        width: 600px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TAG-1 {
          cursor: pointer;
          border-radius: 4px;
          line-height: 0px !important;
          text-align: center;
          width: 100px;
          height: 30px;
          border: 1px solid #e4e7ed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
        }
        .TAG-2 {
          border: none;
          background: #00bfbf;
          color: #fff;
        }
      }
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
</style>
