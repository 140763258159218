<template>
  <div>
    <!-- 搜索列 -->
    <div class="merch-search">
      <div class="search-box">
        <!-- 类型选择 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">经营名称:</div> -->
          <el-input
            clearable
            placeholder="请输入经营名称"
            v-model="searchData.keyWords"
          >
          </el-input>
        </div>
        <!-- 搜索 -->
        <div class="sch-1 sch-2">
          <el-button type="primary" size="mini" @click="handleCurrentChange(1)">
          查 询
          </el-button>
          <el-button
            type="success"
            size="mini"
            @click="toAdd"
            v-if="allJurisdiction.manageType88"
          >
            新建
          </el-button>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="merch-table scorr-roll">
      <div>
        <el-table :data="manageData.data" border style="width: 100%">
          <el-table-column prop="id" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="address" label="经营类别" align="center">
            <template slot-scope="{ row }">
              <el-tooltip effect="dark" :content="row.type" placement="bottom">
                <div class="exceed">
                  {{ row.type }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="会员数" align="center">
            <template slot-scope="{ row }">
              <div class="exceed_color exceed">
                {{ row.user_count }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="供应商数" align="center">
            <template slot-scope="{ row }">
              <div class="exceed_color exceed">
                {{ row.business_count }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="商品数" align="center">
            <template slot-scope="{ row }">
              <div class="exceed_color exceed">
                {{ row.goods_count }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            min-width="200"
          >
            <template slot-scope="{ row }">
              <div class="userBtn">
                <!--  -->
                <div
                  @click="toReact(row.id)"
                  v-if="allJurisdiction.manageType87"
                >
                  编辑
                </div>
                <div
                  @click="GoodsQ(row.id)"
                  v-if="allJurisdiction.manageType89"
                >
                  商品资质
                </div>
                <div
                  @click="transfer(row.id)"
                  v-if="allJurisdiction.manageType90"
                >
                  转移
                </div>
                <el-popconfirm
                  @confirm="deletType(row.id)"
                  title="确定删除当前经营类别？删除后用户将无法选取该类别。"
                >
                  <div slot="reference" v-if="allJurisdiction.manageType91">
                    删除
                  </div>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :page-size="manageData.per_page"
        layout="total, prev, pager, next, jumper"
        :total="manageData.total"
        :current-page="searchData.page"
      >
      </el-pagination>
    </div>
    <!-- 批量转移 -->
    <div>
      <el-dialog
        :visible.sync="isTran"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="800px"
      >
        <div>
          <!-- tag标签 -->
          <div class="merch-tag">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="转移" name="0"> </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 内容 -->
          <div class="merch-content-top">
            <el-form ref="form" label-width="160px">
              <el-form-item label="转移类型">
                <div class="chack-type">
                  <div
                    class="TAG-1"
                    @click="transferData.type = `user`"
                    :class="transferData.type == 'user' ? 'TAG-2' : ''"
                  >
                    会员
                  </div>
                  <div
                    class="TAG-1"
                    @click="transferData.type = `goods`"
                    :class="transferData.type == 'goods' ? 'TAG-2' : ''"
                  >
                    商品
                  </div>
                  <div
                    class="TAG-1"
                    @click="transferData.type = `business`"
                    :class="transferData.type == 'business' ? 'TAG-2' : ''"
                  >
                    供应商
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="选择转移后的经营类别">
                <div>
                  <el-select
                    v-model="transferData.new_scope_id"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in allSelectList"
                      :key="item.id"
                      :label="item.type"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div>转移后，所以资料全部转移，请谨慎操作</div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isTran = false">取 消</el-button>
          <el-button type="primary" @click="affirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 新建 -->
    <el-dialog :title="addTiTle" :visible.sync="isCategory" width="600px">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="经营类型">
            <el-input v-model="AddData.type"></el-input>
          </el-form-item>
          <el-form-item label="中药">
            <el-switch
              v-model="AddData.medicine_type"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isCategory = false">取 消</el-button>
        <el-button type="primary" @click="AddType">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 配置资质 -->
    <div>
      <el-dialog
        title="配置资质"
        :visible.sync="isdeploy"
        width="800px"
        @close="refreshData"
      >
        <div>
          <!-- 搜索列 -->
          <div class="merch-search">
            <div class="search-box">
              <div class="sch-1 sch-2">
                <div class="sch-title">资质状态:</div>
                <el-select
                  v-model="deployApi.select"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in userType"
                    :key="item.tyepe"
                    :label="item.name"
                    :value="item.tyepe"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="sch-1 sch-2">
                <div class="sch-title">资质名称:</div>
                <el-input
                  placeholder="请输入资质名称"
                  v-model="deployApi.keyWords"
                ></el-input>
              </div>
              <div class="sch-1 sch-2">
                <el-button
                  @click="handleCurrentChang1(1)"
                  type="primary"
                  size="mini"
                >
                查 询
                </el-button>
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="table-src scorr-roll">
            <el-table :data="deplyData.data" border style="width: 100%">
              <el-table-column prop="qualify_id" label="编号" align="center">
              </el-table-column>
              <el-table-column
                prop="qualify_name"
                label="注册资质"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="address"
                label="是否需要提供资质"
                align="center"
                width="250"
              >
                <template slot-scope="{ row }">
                  <el-switch
                    @change="changeSwitch(row)"
                    v-model="row.scope_id"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="xzGoodS.scope_id"
                    :inactive-value="0"
                  >
                  </el-switch>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="merch-page">
            <el-pagination
              background
              @current-change="handleCurrentChang1"
              :page-size="deplyData.per_page"
              layout="total, prev, pager, next, jumper"
              :total="deplyData.total"
              :current-page="deplyData.current_page"
            >
            </el-pagination>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" @click="isdeploy = false">关闭</el-button> -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics");
export default {
  data() {
    return {
      form: {},
      activeName: 0,
      isCategory: false, //新增弹框
      searchData: {
        page: 1,
      },
      isTran: false, //控制转移弹框
      AddData: {},
      transferData: {
        type: "user",
        new_scope_id: "",
        old_scope_id: "",
      },
      isdeploy: false,
      deployApi: {
        select: "",
        page: 1,
      },
      allSelectList: [],
      deplyData: {}, //商品资质数据
      xzGoodS: {
        scope_id: "",
        qualify_id: "",
        action: "",
      },
      userType: [
        {
          name: "开",
          tyepe: "1",
        },
        {
          name: "关",
          tyepe: "0",
        },
      ],
      addTiTle: "",
    };
  },
  computed: {
    ...mapState(["manageData"]),
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    // this.getscopelist({ page: 1 });
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
    }
    this.toSearch();
    this.allSearch();
  },
  methods: {
    ...commonIndex.mapActions(["getbasisscope"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    ...mapActions([
      "getscopelist",
      "postscopeadd",
      "postbatchTransfer",
      "getgoodsQualifylist",
      "postgoodsQualifychangeSelect",
      "posttypedelete",
      "posttypeedit",
      "gettypeedit",
      "typeSelectList",
    ]),
    // 获取编辑详情
    async toReact(val) {
      this.addTiTle = "编辑经营类别";
      let data = await this.gettypeedit({ id: val });
      if (data.code == 200) {
        this.isCategory = true;
        this.AddData = data.data;
        if (this.AddData.medicine_type == 1) {
          this.AddData.medicine_type = true;
        } else {
          this.AddData.medicine_type = false;
        }
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 删除类别
    async deletType(val) {
      let data = await this.posttypedelete({ id: val });
      if (data.code == 200) {
        // this.toSearch();
        // this.allSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
        if (this.manageData.data.length == 1 && this.searchData.page != 1) {
          this.searchData.page--;
        }
        this.getscopelist(this.searchData);
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 选择资质
    async changeSwitch(val) {
      //console.log(val.scope_id == 0);
      this.xzGoodS.qualify_id = val.qualify_id;
      this.xzGoodS.action = val.scope_id == 0 ? "delete" : "add";
      let data = await this.postgoodsQualifychangeSelect(this.xzGoodS);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
      this.GoodsQ(this.deployApi.scope_id);
    },
    // 获取商品资质列表
    async GoodsQ(val) {
      this.deployApi.scope_id = val;
      let data = await this.getgoodsQualifylist(this.deployApi);
      if (data.code == 200) {
        this.xzGoodS.scope_id = val;
        this.deplyData = data.data;
        this.isdeploy = true;
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 转移
    transfer(id) {
      this.transferData.old_scope_id = id;
      this.isTran = true;
    },
    // 确认转移
    async affirm() {
      if (this.transferData.new_scope_id == "") {
        this.$message({
          message: "请选择转移后的经营类别",
          type: "error",
        });
        return;
      }
      let data = await this.postbatchTransfer(this.transferData);
      //console.log(data, "123");
      if (data.code == 200) {
        this.toSearch();
        this.isTran = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 前往新增
    toAdd() {
      this.AddData = {};
      this.addTiTle = "新增经营类别";
      this.isCategory = true;
    },
    // 新增
    async AddType() {
      let data = {};
      if (this.AddData.medicine_type) {
        this.AddData.medicine_type = 1;
      } else {
        this.AddData.medicine_type = 2;
      }
      if (!this.AddData.id) {
        data = await this.postscopeadd(this.AddData);
      } else {
        data = await this.posttypeedit(this.AddData);
      }
      if (data.code == 200) {
        this.isCategory = false;
        this.toSearch();
        this.allSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
        this.getbasisscope();
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 关闭对话框回调
    refreshData() {
      this.deployApi.select = "";
    },
    handleCurrentChange(e) {
      //console.log(e);
      this.searchData.page = e;
      this.toSearch();
    },
    handleCurrentChang1(e) {
      this.deployApi.page = e;
      this.GoodsQ(this.deployApi.scope_id);
    },
    async allSearch() {
      let res = await this.typeSelectList();
      this.allSelectList = res.data;
    },
    toSearch() {
      this.getscopelist(this.searchData);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      save.activeName = 0;
      save.router = router;
      this.changeSeach(save);
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .merch-content-top {
      padding-top: 10px;
      .chack-type {
        padding: 0px 40px;
        width: 600px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TAG-1 {
          cursor: pointer;
          border-radius: 4px;
          line-height: 0px !important;
          text-align: center;
          width: 100px;
          height: 30px;
          border: 1px solid #e4e7ed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
        }
        .TAG-2 {
          border: none;
          background: #00bfbf;
          color: #fff;
        }
      }
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 60vh;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
// 搜索行样式
.merch-search {
  margin-top: 1%;
  width: 100%;
  height: 50px;
  .search-box {
    display: flex;
    flex-wrap: wrap;
  }
  .sch-1 {
    width: 18%;
    min-width: 200px;
    display: flex;

    .sch-title {
      width: 40%;
      min-width: 80px;
      height: 100%;
      font-size: 13px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
  }
  .sch-2 {
    margin-right: 2%;
  }
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.table-src {
  margin-top: 20px;
  height: 300px;
}
</style>
