<template>
  <div class="manageType MERCH">
    <div class="merch-card">
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="经营类别" name="0"> </el-tab-pane>
          <el-tab-pane label="商品资质库" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 经营类别列表 -->
      <div v-if="activeName == 0">
        <Sort />
      </div>
      <!-- 商品资质库 -->
      <div v-if="activeName == 1">
        <GoodsWar />
      </div>
    </div>
  </div>
</template>

<script>
import Sort from "./typeCom/sortTypeList.vue"; //经营类别列表
import GoodsWar from "./typeCom/warehouse.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Basics");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      form: {},
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "child",
        checkStrictly: true,
      }, //级联选择器配置项
      AreaArr: [],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      isTran: false, //控制转移弹框
    };
  },
  components: {
    Sort,
    GoodsWar,
  },
  computed: {
    ...commonIndex.mapState(["AreaList", "userType", "mainHright",'detailsSeach']),
  },
  created() {
    let router=this.$router.history.current.path.substr(1)
    if(this.detailsSeach[router]){
      this.activeName=this.detailsSeach[router].activeName
    }
    // this.getscopelist({ page: 1 });
  },
  methods: {
    ...mapActions(["getscopelist", "getscopegoodsQualify"]),
    toSearch() {},
    // tag标签切换

    handleClick(tab, event) {
      // if (tab == 1) {
      //   this.getscopegoodsQualify({ page: 1 });
      // } else {
      //   this.getscopelist({ page: 1 });
      // }
    },

    // 打开转移窗口
    transferS() {
      this.isTran = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06B7AE;
}
.el-tabs__item:hover {
  color: #06B7AE;
}
.el-tabs__active-bar {
  background-color: #06B7AE;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .merch-content-top {
      padding-top: 10px;
      .chack-type {
        padding: 0px 40px;
        width: 600px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TAG-1 {
          cursor: pointer;
          border-radius: 4px;
          line-height: 0px !important;
          text-align: center;
          width: 100px;
          height: 30px;
          border: 1px solid #e4e7ed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
        }
        .TAG-2 {
          border: none;
          background: #00bfbf;
          color: #fff;
        }
      }
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
</style>
